/*=========================================================================================
File Name: AllServices.vue
Description: All Services Page.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/
<template>
<div>
  <!-- All Services Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Service Area Component -->
  <service-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import ServiceArea from "../../components/ServiceArea";
export default {
  name: "AllServices",
  components: {ServiceArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: 'Services',
        pageTitle: 'All Services'
      }
    }
  }
}
</script>

<style scoped>

</style>