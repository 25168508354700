<template>
  <!-- Breadcrumb Area Start -->
  <section class="ekifa-breadcrumb-area section_80">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="breadcrumb-box">
            <ul>
              <li><b-link to="/">home</b-link></li>
              <li>|</li>
              <li>{{breadcrumb.page}}</li>
            </ul>
            <h2>{{breadcrumb.pageTitle}}</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Breadcrumb Area End -->
</template>

<script>
export default {
  name: "BreadcrumbArea",
  props: {
    breadcrumb: {
      page: '',
      pageTitle: ''
    }
  }
}
</script>

<style scoped>

</style>